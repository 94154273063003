import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../../components/images/imageCoaching.js'
import ImageDevelopement from '../../components/images/imageDevelopement.js'
import ImageClimat from '../../components/images/imageClimat.js'
import ImageChange from '../../components/images/imageChange.js'
import ImageConflit from '../../components/images/imageConflit.js'
import ImageHarmo1 from '../../components/images/imageHarmo1.js'

const Harmonisation = () => (
  <Layout>
    <SEO title="Harmonisation du climat de travail | Climat de travail et santé organisationnelle" />
    <PageHeader
        header="Harmonisation du climat de travail"
        text="Vous souhaitez aligner vos objectifs organisationnels, accélérer la prise de décision collective et accroître l'engagement de vos équipes ?    Oui, mais par où commencer? Où concentrer vos efforts? "
        quote="Ce n’est ni la finance, ni la stratégie, ni la technologie, mais le travail d’équipe qui demeure l’avantage compétitif ultime, parce qu’il est si puissant et si rare <span>- Patrick Lencioni </span>"
        background="climat" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Pour ne pas vous perdre en chemin, un diagnostic organisationnel rigoureux vous permet de cerner la situation afin d’y voir plus clair pour cibler les bonnes priorités … et surtout de prendre les meilleures décisions! </p>
        <div className="flex-container pad2">
            <div className="half"><ImageHarmo1 /></div>
            <div className="half">
                <p style={{'text-align':'left','padding':'0 10%'}}>S’appuyant sur des rencontres individuelles ou de groupe, notre démarche de diagnostic organisationnel fournit à vos collaborateurs l’opportunité d’exprimer tant leurs inconforts et malaises que leurs perspectives et visions.<br /><br />Nous vous épaulons dans la réalisation d’un bilan qui met en lumière les sources, la nature et les causes des problématiques que vous rencontrez. N’est-ce pas aussi une merveilleuse occasion de mettre en lumière les forces de vos équipes?</p>
            </div>

        </div>
        <p class="service-presentation2">Pas de doute, nous sommes en mesure de vous soutenir afin de stimuler l'engagement de vos équipes afin de vous propulser vers l’avenir!
        </p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>

    </div>
  </Layout>
)

export default Harmonisation
